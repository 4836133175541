import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { Logo } from './Logo/Logo';
import { NavItems } from '../Services/NavItems'

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    _renderAuthenticateView = () => {
        return (
            <ul className="navbar-nav flex-grow">
                {this._getViewByRoles()}
            </ul>
        );
    }

    _getNavItem = (navItem) => {
        return (
            <NavItem key={navItem.url}>
                <NavLink tag={Link} className="text-dark" to={navItem.url}>{navItem.name}</NavLink>
            </NavItem>
        );
    }

    _getViewByRoles = () => {
        const token = localStorage.getItem("access_token");

        if (!token)
            return this._getNavItem(NavItems.filter(item => item.role == null)[0]);

        const base64Url = token.split('.')[1]
        const base64 = base64Url.replace('-', '+').replace('_', '/')
        const session = JSON.parse(window.atob(base64))
        const roles = session['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];

        return NavItems.filter(item => item.role !== null).map(item => {
            if (roles.includes(item.role))
                return this._getNavItem(item);
            if (item.role === 'logout')
                return this._getNavItem(item);
            return null;
        });
    }

    render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                    <Container>
                        <NavbarBrand tag={Link} to="/">
                            <Logo />
                        </NavbarBrand>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow">
                                {this._renderAuthenticateView()}
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
}
