import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { Link, Redirect, withRouter} from 'react-router-dom';
import { NavItems } from '../Services/NavItems'
import Roles from '../Services/Roles';
import './Home.css'

class Home extends Component {
    static displayName = Home.name;

    _getNavItem = (navItem) => {

        const getColorByRole = () => {
            switch (navItem.role) {
                case Roles.getKeyActivatorRole:
                    return 'primary';
                case Roles.getKeyViewerRole:
                    return 'warning';
                case Roles.getKeyCreatorRole:
                    return 'danger';
                default:
                    return 'primary';
            }
        }

        return (
            <Link  key={navItem.url} to={navItem.url}>
                <Button className="ButtonItem" color={getColorByRole()} size="lg">
                    {navItem.name}
                </Button>
            </Link>
        );
    }

    _getViewByRoles = (roles) => {
        return NavItems.filter(item => item.role !== null && item.role !== 'logout').map(item => {
            if (roles.includes(item.role))
                return this._getNavItem(item);
            return null;
        });
    }

    componentDidMount() {
        const token = localStorage.getItem("access_token");

        if (token) {
            const base64Url = token.split('.')[1]
            const base64 = base64Url.replace('-', '+').replace('_', '/')
            const { exp } = JSON.parse(window.atob(base64))
            if (Date.now() >= exp * 1000) {
                this.setState({ errorMessage: "Login timeout expired" });
                localStorage.removeItem("access_token");
                this.props.refreshNavMenu();
                this.props.history.push('/');
            }
        }
    }

    render() {
        const token = localStorage.getItem("access_token");

        if (!token)
            return <Redirect to='/login' />

        const base64Url = token.split('.')[1]
        const base64 = base64Url.replace('-', '+').replace('_', '/')
        const session = JSON.parse(window.atob(base64))
        const roles = session['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];

        return (
            <div>
                <h1>Unitronics Licensing</h1>
                <p>Welcome to Unitronics Licensing website</p>
                <p>This site is for now only for Unitronics internal use, don't send this link to some one outsite Unitronics.</p>
                <p>This site allows you to handle the licensing of Features in the PLCs.</p>

                <div className="ButtonsContainer">
                    {this._getViewByRoles(roles)}
                </div>

            </div>
        );
    }
}

export default withRouter(Home);
