import React from 'react'
import styles from './Logo.module.css'

import logo from '../../assets/UnitronicsLogo.png'

export const Logo = () => {
    return (
        <div className={styles.Logo}>
            <img src={logo} alt="Unitronics" />
            <span>Unitronics</span>
        </div>
    )
}