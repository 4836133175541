import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import KeyGenerator from './components/KeyGenerator/KeyGenerator';
import Home from './components/Home';
import './custom.css'
import Activate from './components/Activate/Activate';
import Deactivate from './components/Deactivate/Deactivate';
import Login from './components/Login/Login';
import { Logout } from './components/Logout/Logout';
import KeyStatus  from './components/KeyStatus/KeyStatus';


export default class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            roles: null
        };
    }

    updateAuthorization(roles) {
        this.setState({ roles });
    }

    render() {
        return (
            <Layout>
                <Route path='/' exact ><Home loggedInRoles={this.state.roles} refreshNavMenu={() => this.forceUpdate()} /></Route>
                <Route path='/key-generator' ><KeyGenerator refreshNavMenu={() => this.forceUpdate()} /></Route>
                <Route path='/key-status' ><KeyStatus refreshNavMenu={() => this.forceUpdate()} /></Route>
                <Route path='/activate-offline'><Activate refreshNavMenu={() => this.forceUpdate()} /></Route>
                <Route path='/deactivate-offline'><Deactivate refreshNavMenu={() => this.forceUpdate()} /></Route>
                <Route path='/login'><Login refreshNavMenu={() => this.forceUpdate()} /></Route>
                <Route path='/logout'><Logout refreshNavMenu={() => this.forceUpdate()} /></Route>
            </Layout>
        );
    }
}
