import Urls from './Urls';
import Roles from './Roles';

export const NavItems = [
    {
        name: 'Activate',
        url: Urls.nav_activate_offline,
        role: Roles.getKeyActivatorRole,
        order: 1,
    },
    {
        name: 'Deactivate',
        url: Urls.nav_deactivate_offline,
        role: Roles.getKeyActivatorRole,
        order: 2,
    },
    {
        name: 'Key status',
        url: Urls.nav_key_status,
        role: Roles.getKeyViewerRole,
        order: 3,
    },
    {
        name: 'Key Generator',
        url: Urls.nav_key_generator,
        role: Roles.getKeyCreatorRole,
        order: 4,
    },
    {
        name: 'Logout',
        url: Urls.nav_logout,
        role: 'logout',
        order: 5,
    },
    {
        name: 'Login',
        url: Urls.nav_login,
        role: null,
        order: 6,
    },
]