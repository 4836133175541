import React, { Component } from 'react'
import './Upload.css'
import Dropzone from "../Dropzone/Dropzone";
import Progress from "../Progress/Progress";
import Axios from 'axios';
import doneImage from '../../assets/check_circle_outline-24px.svg'


export class Upload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            uploading: false,
            uploadProgress: {},
            successfullUploaded: false,
            error: ''
        };

        this.onFilesAdded = this.onFilesAdded.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);
        this.sendRequest = this.sendRequest.bind(this);
        this.renderActions = this.renderActions.bind(this);
    }

    onFilesAdded(files) {
        this.setState(prevState => ({
            files: prevState.files.concat(files)
        }));
    }

    async uploadFiles() {
        this.setState({ uploadProgress: {}, uploading: true });
        const promises = [];
        this.state.files.forEach(file => {
            promises.push(this.sendRequest(file));
        });
        try {
            await Promise.all(promises);

            this.setState({ successfullUploaded: true, uploading: false, error: '' });
        } catch (error) {
            let errorMessage = '';

            if (error.response) {
                // The server responded with a status code outside the range of 2xx
                errorMessage = await error.response.data.text();
            } else {
                errorMessage = error.message
            }

            // Not Production ready! Do some error handling here instead...
            this.setState({ successfullUploaded: true, uploading: false, error: errorMessage });
        }
    }

    sendRequest(file) {
        return new Promise((resolve, reject) => {
           
            const formData = new FormData();
            formData.append("file", file, file.name);

            Axios.post(this.props.sendToUrl, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
                onUploadProgress: (progressEvent) => {
                    if (progressEvent.lengthComputable) {
                        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        const copy = { ...this.state.uploadProgress };
                        copy[file.name] = {
                            state: "pending",
                            percentage: percentCompleted
                        };
                        this.setState({ uploadProgress: copy });
                    }
                },
                responseType: 'blob'
            }).then((response) => {

                const disposition = response.headers['content-disposition'];
                var filename = 'response_' + this.state.files[0].name;
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1])
                    filename = matches[1].replace(/['"]/g, '');

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();

                const copy = { ...this.state.uploadProgress };
                copy[file.name] = { state: "done", percentage: 100 };
                this.setState({ uploadProgress: copy });

                resolve(response);
            }).catch(error => {
                console.log(error);
                const copy = { ...this.state.uploadProgress };
                copy[file.name] = { state: "error", percentage: 0 };
                this.setState({ uploadProgress: copy });
                reject(error);
            })
        });
    }

    renderProgress(file) {
        const uploadProgress = this.state.uploadProgress[file.name];
        if (this.state.uploading || this.state.successfullUploaded) {
            return (
                <div className="ProgressWrapper">
                    <Progress progress={uploadProgress ? uploadProgress.percentage : 0} />
                    <img
                        className="CheckIcon"
                        alt="done"
                        src={doneImage}
                        style={{
                            opacity:
                                uploadProgress && uploadProgress.state === "done" ? 0.5 : 0
                        }}
                    />
                </div>
            );
        }
    }

    renderActions() {
        if (this.state.successfullUploaded) {
            return (
                <button
                    onClick={() =>
                        this.setState({ files: [], successfullUploaded: false, error: '' })
                    }
                >
                    Clear
                </button>
            );
        } else {
            return (
                <button
                    disabled={this.state.files.length < 0 || this.state.uploading}
                    onClick={this.uploadFiles}
                >
                    Upload
                </button>
            );
        }
    }

    render() {
        return (
            <div className="Upload">
                {this.state.error && <div className='error'>{this.state.error}</div>}
                <div className="Content">
                    <div>
                        <Dropzone
                            onFilesAdded={this.onFilesAdded}
                            disabled={this.state.uploading || this.state.successfullUploaded} />
                    </div>
                    <div className="Files">
                        {this.state.files.map(file => {
                            return (
                                <div key={file.name} className="Row">
                                    <span className="Filename">{file.name}</span>
                                    {this.renderProgress(file)}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="Actions">{this.renderActions()}</div>
            </div>
        );
    }
}