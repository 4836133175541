const getKeyCreatorRole = "KeyCreator",
    getKeyViewerRole = "KeyViewer",
    getKeyActivatorRole = "KeyActivator"
    ;
export default class Urls {

    static get getKeyCreatorRole() {
        return getKeyCreatorRole;
    }

    static get getKeyViewerRole() {
        return getKeyViewerRole;
    }

    static get getKeyActivatorRole() {
        return getKeyActivatorRole;
    }
}