import axios from "axios";
import Urls from "../Services/Urls";
import { history } from '../index';
import { toast } from 'react-toastify'

// Add a request interceptor
axios.interceptors.request.use(
    config => {
        const token = localStorage.getItem("access_token");

        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    error => {
        console.log('Hi yehuda 3')
        Promise.reject(error)
    }
);

//Add a response interceptor
axios.interceptors.response.use(
    response => {
        return response
    },
    error => {

        if (error.response.status === 401) {
            history.push(Urls.nav_login);
            return Promise.reject(error);
        }

        //    const originalRequest = error.config;

        //if (error.response.status === 401 && !originalRequest._retry) {

        //    originalRequest._retry = true;
        //    const refreshToken = localStorageService.getRefreshToken();
        //    return axios.post(Urls.postLogin,//'/auth/token',
        //        {
        //            "refresh_token": refreshToken
        //        })
        //        .then(res => {
        //            if (res.status === 201) {
        //                localStorageService.setToken(res.data);
        //                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorageService.getAccessToken();
        //                return axios(originalRequest);
        //            }
        //        })
        //}

        toast.error("Server error - check the terminal for more info");
        return Promise.reject(error);
    }
);

export default axios;