import React from 'react'
import Axios from 'axios';
import Pagination from '@material-ui/lab/Pagination';
import { Container, Alert} from 'reactstrap';
import { Table } from './Table';


export const KeyStatusTable = (props) => {

    // We'll start our table without any data
    const [data, setData] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [pageCount, setPageCount] = React.useState(0)
    const [totalCount, setTotalCount] = React.useState(0)
    const [page, setPage] = React.useState(1);
    const fetchIdRef = React.useRef(0)

    const fetchData = (() => {
        // Give this fetch an ID
        const fetchId = ++fetchIdRef.current

        // Set the loading state
        setLoading(true)

        let url = props.url.replace("pageIndexPlaceholder", page);

        Axios.get(url)
            .then(res => {
                if (fetchId === fetchIdRef.current) {
                    setData(res.data.items);
                    setPageCount(res.data.totalPages);
                    setTotalCount(res.data.totalCount);
                    setLoading(false);
                }

            })
            .catch(error => {
                console.log(error);
            });

    })

    React.useEffect(() => {
        fetchData()
    }, [page])

    React.useEffect(() => {
        setPage(1);
        fetchData()
    }, [props.url])

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    if (!data.length)
        return (
            <Alert color="danger" fade={false} isOpen>
                <p>No key found, try a 'New Search'.</p>
            </Alert>
        );

    return (
        <Container>

            <Pagination count={pageCount} page={page} onChange={handlePageChange} />
            <Table data={data} loading={loading} />

        </Container>
    )
}