const version = 'api/v1/',
    getKeyFeatures = 'key/features',
    getKeyStatus = 'key/status',
    postKeyDeactivateFile = 'key/deactivate/file',
    postKeyActivateFile = 'key/activate/file',
    postLogin = 'login',
    postLogout = 'logout',
    postKeycreate = 'key/create',


    nav_home = '/',
    nav_key_generator = '/key-generator',
    nav_key_status = '/key-status',
    nav_activate_offline = '/activate-offline',
    nav_deactivate_offline = '/deactivate-offline',
    nav_login = '/login',
    nav_logout = '/logout'
    ;
export default class Urls {

    static get getKeyFeatures() {
        return version + getKeyFeatures;
    }

    static get getKeyStatus() {
        return version + getKeyStatus;
    }

    static get postKeyDeactivateFile() {
        return version + postKeyDeactivateFile;
    }

    static get postKeyActivateFile() {
        return version + postKeyActivateFile;
    }

    static get postKeycreate() {
        return version + postKeycreate;
    }

    static get postLogin() {
        return postLogin;
    }

    static get postLogout() {
        return postLogout;
    }




    static get nav_home() {
        return nav_home;
    }
    static get nav_key_generator() {
        return nav_key_generator;
    }
    static get nav_key_status() {
        return nav_key_status;
    }
    static get nav_activate_offline() {
        return nav_activate_offline;
    }
    static get nav_deactivate_offline() {
        return nav_deactivate_offline;
    }
    static get nav_login() {
        return nav_login;
    }
    static get nav_logout() {
        return nav_logout;
    }
}

