import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import styles from './Deactivate.module.css'
import { Upload } from '../Upload/Upload';
import urls from '../../Services/Urls.js';
import { Redirect, withRouter } from 'react-router-dom';

const Deactivate = (props) => { 

    useEffect(() => {
        const token = localStorage.getItem("access_token");

        if (token) {
            const base64Url = token.split('.')[1]
            const base64 = base64Url.replace('-', '+').replace('_', '/')
            const { exp } = JSON.parse(window.atob(base64))
            if (Date.now() >= exp * 1000) {
                localStorage.removeItem("access_token");
                props.refreshNavMenu();
                props.history.push('/');
            }
        }
    }, []);

    if (!localStorage.getItem("access_token"))
        return <Redirect to='/login' />

    return (
        <Container className={styles.Activate}>
            <h1>Deactivate</h1>
            <p>Select Deactivate files from the PLC to deactivate them.</p>

            <ul>
                <li><strong>Deactivate</strong>. There you can send an 'Deactivate request' file, and get the 'Deactivate respons' file for the customer. This is needed if the customer PLC is not connected to the Ethernet, he should need to export a Activation request file and send it to support, and get back the 'Deactivate respons' file.</li>
            </ul>

            <Upload sendToUrl={urls.postKeyDeactivateFile} />
        </Container>
    );  
}

export default withRouter(Deactivate);
