import React, { Component } from 'react';
import Axios from 'axios';
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css';
import { Container, Alert, Button } from 'reactstrap';
import NumericInput from 'react-numeric-input';
import styles from './KeyGenerator.module.css'
import Urls from '../../Services/Urls.js';
import { Redirect, withRouter } from 'react-router-dom';
import { Table } from '../Table/Table';

class KeyGenerator extends Component {
    static displayName = KeyGenerator.name;

    constructor(props) {
        super(props);
        this.state = {
            keyTypes: [],
            selectedKeyType: '',
            keyQuantity: 1,
            generatedKeys: null,
            errorMessage: null,
            showError: false,

            loaded: false,
            error: false
        };
    }

    _updateError = (errorMessage) => {
        this.setState({
            showError: errorMessage !== null,
            errorMessage
        });
    }

    _onkeyTypeSelect = (selectedKeyType) => {
        this.setState({ selectedKeyType: selectedKeyType.value })
    }

    _onkeyQuantityChanged = (valueAsNumber, valueAsString) => {
        this.setState({
            keyQuantity: valueAsNumber
        });
    }

    _onGenerateClicked = () => {

        if (this.state.keyQuantity > 0 && this.state.selectedKeyType !== '') {
            this._postGenerateToServer(this.state.selectedKeyType, this.state.keyQuantity);
            this._updateError(null);
        }
        else {
            this._updateError("Select a Feature and an Amount before you click on the 'Generate' button");
            // alert("Select a Feature and an Amount before you click on the 'Generate' button")
        }
    }

    _postGenerateToServer = (feature, amount) => {

        const params = {
            Feature: feature,
            Amount: amount
        }

        Axios.post(Urls.postKeycreate, params).then(res => {
            this.setState({
                generatedKeys: res.data
            })
        }).catch(error => {
            console.log(error);
            this.setState({ error: true })
        });
    }

    componentDidMount() {
        console.log(Urls.postKeycreate);
        Axios.get(Urls.getKeyFeatures).then(response => {

            this.setState({
                loaded: true,
                keyTypes: response.data
            })

        }).catch(error => {
            console.log(error);
            this.setState({ error: true })
        });

        const token = localStorage.getItem("access_token");

        if (token) {
            const base64Url = token.split('.')[1]
            const base64 = base64Url.replace('-', '+').replace('_', '/')
            const { exp } = JSON.parse(window.atob(base64))
            if (Date.now() >= exp * 1000) {
                this.setState({ errorMessage: "Login timeout expired" });
                localStorage.removeItem("access_token");
                this.props.refreshNavMenu();
                this.props.history.push('/');
            }
        }
    }

    render() {
        if (!localStorage.getItem("access_token"))
            return <Redirect to='/login' />

        const keyTable = this.state.generatedKeys && <Table data={this.state.generatedKeys.map((key) => {
            return {
                    Key: key.key,
                    Feature: key.feature.label
                }
        })} />

        return (
            <Container className={styles.KeyGenerator}>
                <h1>Key generator</h1>

                <ul>
                    <li><strong>Key Generator</strong>. There you are able to create new keys for a specific Feature, send it the the customer to activate his PLC.</li>
                </ul>

                <p>Select a Feature:</p>
                <Dropdown options={this.state.keyTypes} value={this.state.selectedKeyType} onChange={this._onkeyTypeSelect} placeholder="Select a key type" />

                <p>Enter the amount of keys to generate for this feature:</p>
                <NumericInput min={0} max={100} value={this.state.keyQuantity} onChange={this._onkeyQuantityChanged} />

                <p>Click the 'Generate keys' button to get the keys from the server.</p>
                <Button color="primary" className={styles.Button} onClick={this._onGenerateClicked}>Generate keys</Button>

                <Alert className={styles.Alert} color="danger" fade={false} isOpen={this.state.showError}>
                    {this.state.errorMessage}
                </Alert>
                {keyTable}
            </Container>
        );
    }
}

export default withRouter(KeyGenerator);
