import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import App from './App';
import registerServiceWorker, { unregister } from './registerServiceWorker';
import Axios from './Axios/Axios';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
Axios.defaults.baseURL = baseUrl;

ReactDOM.render(
    <Router history={history} basename={baseUrl}>
        <App />
    </Router>,
    rootElement);

//registerServiceWorker();
unregister();

