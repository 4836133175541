import React, { useEffect} from 'react';
import { Redirect } from 'react-router-dom'
import Axios from 'axios';
import Urls from '../../Services/Urls';

export const Logout = (props) => {

    useEffect(() => {

        localStorage.removeItem("access_token");
        props.refreshNavMenu()

        Axios.post(Urls.postLogout)
            .then(res => { })
            .catch(error => {
            console.log(error);
        })

    });

    return (
        <Redirect to="/login" />
    )
}