import React from 'react'
import { Container } from 'reactstrap';
import { ExportCSV } from '../ExportCSV/ExportCSV';
import CircularProgress from '@material-ui/core/CircularProgress';
import './Table.css' 

export const Table = (props) => {

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const beautifyKey = (key) => capitalizeFirstLetter(key).split(/(?=[A-Z])/).join(' ');

    const getKeys = () => {
        return props.data.length && Object.keys(props.data[0])
    }

    const getHeader = () => {
        var keys = getKeys() || [];
        return keys.map((key, index) => {
            return <th key={key}>{beautifyKey(key)}</th>
        })
    }

    const getRowsData = () => {
        var items = props.data;
        var keys = getKeys();
        return items.map((row, index) => {
            return <tr key={index}><RenderRow key={index} data={row} keys={keys} /></tr>
        })
    }


    const RenderRow = (props) => {
        return props.keys.map((key, index) => {
            return <td key={key}>{props.data[key]}</td>
        })
    }

    return (
        <Container>
            <ExportCSV className="ExportButton" csvData={props.data} fileName='Generated keys table' />
            <div>
                <table className='table table-striped' aria-labelledby="tabelLabel" id="table-to-xls">
                    <thead>
                        <tr>{getHeader()}</tr>
                    </thead>
                    <tbody>
                        {getRowsData()}
                    </tbody>
                </table>
                {props.loading && <CircularProgress size={24} />}
            </div>
        </Container>
    )
}