import React from 'react'
import { Button } from 'reactstrap';
import * as FileSaver from 'file-saver';
import writeXlsxFile from 'write-excel-file'

export const ExportCSV = ({csvData, fileName}) => {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCSV = async (csvData, fileName) => {
        if (csvData && csvData.length) {
            var header = Object.keys(csvData[0]).map(k => { return { value: k, fontWeight: 'bold' } });
            var rows = csvData.map(r => Object.values(r)).map(r => r.map(c => { return { value: c,  } }));
            const data = [
                header,
                ...rows,
            ];

            const columns = header.map(c => { return { width: 30 } });

            await writeXlsxFile(data, {
                columns,
                fileName: fileName,
                sheet: 'data'
            });
        }
    }

    return (
        <Button color="primary" onClick={(e) => exportToCSV(csvData, fileName)}>Download as XLSX</Button>
    )
}