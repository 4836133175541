import React, { Component } from 'react';
import Axios from 'axios';
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css';
import { Container, Alert, Button, Row, Form, FormGroup, Label, Input } from 'reactstrap';
import Urls from '../../Services/Urls.js';
import { Redirect, withRouter } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import NumericInput from 'react-numeric-input';
import './KeyStatus.css'
import { KeyStatusTable } from '../Table/KeystatusTable';


class KeyStatus extends Component {
    static displayName = KeyStatus.name;

    constructor(props) {
        super(props);
        this.state = {

            keyTypes: [],
            selectedKeyType: '',
            keysStatus: null,
            pageNumber: 1,
            pageSize: 20,
            key: null,
            order: null,
            fromDate: null,
            toDate: null,
            getKeyStatusUrl: '',

            errorMessage: null,
            showError: false,

            loaded: false,
            error: false
        };
    }

    _updateError = (errorMessage) => {
        this.setState({
            showError: errorMessage !== null,
            errorMessage
        });
    }

    _onkeyTypeSelect = (selectedKeyType) => {
        this.setState({ selectedKeyType: selectedKeyType.value })
    }

    _onPageSizeChanged = (valueAsNumber, valueAsString) => {
        this.setState({
            pageSize: valueAsNumber
        });
    }

    _onGetKeyStatusClicked = () => {

        if (this.state.selectedKeyType !== '') {
            this._getKeyStatusFromServer();
            this._updateError(null);
        }
        else {
            this._updateError("Select a Feature");
        }
    }

    _removeEmpty = obj => {
        Object.keys(obj).forEach(key => (obj[key] == null || obj[key] === '') && delete obj[key]);
    };



    _getKeyStatusFromServer = () => {

        const pad = (number) => {
            if (number < 10) {
                return '0' + number;
            }
            return number;
        }

        const getValidDateString = (date) => {
            if (date == null)
                return null;

            return date.getFullYear() +
                '/' + pad(date.getMonth() + 1) +
                '/' + pad(date.getDate()) +
                'T' + pad(date.getHours()) +
                ':' + pad(date.getMinutes()) +
                ':' + pad(date.getSeconds());
        };

        const urlParameters = {
            p: 'pageIndexPlaceholder',
            ps: this.state.pageSize,
            key: this.state.key,
            //order: this.state.order,
            fromDate: getValidDateString(this.state.fromDate),
            toDate: getValidDateString(this.state.toDate),
            feature: this.state.selectedKeyType === 'ALL' ? null : this.state.selectedKeyType
        };

        this._removeEmpty(urlParameters);
        const getKeyStatusUrl = Urls.getKeyStatus + '?' + new URLSearchParams(urlParameters).toString();
        this.setState({ getKeyStatusUrl });
    }

    _getSearchBtnText = () => this.state.getKeyStatusUrl === '' ? "Search keys" : "New Search";

    componentDidMount() {
        Axios.get(Urls.getKeyFeatures).then(response => {
            const kt = ['ALL', ...response.data];
            this.setState({
                loaded: true,
                keyTypes: kt,
                selectedKeyType: kt[0]
            })

        }).catch(error => {
            console.log(error);
            this.setState({ error: true })
        });

        const token = localStorage.getItem("access_token");

        if (token) {
            const base64Url = token.split('.')[1]
            const base64 = base64Url.replace('-', '+').replace('_', '/')
            const { exp } = JSON.parse(window.atob(base64))
            if (Date.now() >= exp * 1000) {
                this.setState({ errorMessage: "Login timeout expired" });
                localStorage.removeItem("access_token");
                this.props.refreshNavMenu();
                this.props.history.push('/');
            }
        }
    }

    render() {
        if (!localStorage.getItem("access_token"))
            return <Redirect to='/login' />

        const keyTable = this.state.getKeyStatusUrl !== '' && <KeyStatusTable url={this.state.getKeyStatusUrl} />

        return (
            <Container >
                <h1>Key status</h1>

                <ul>
                    <li><strong>Key status</strong> There you are able to get status of keys.</li>
                </ul>

                <Form>
                    <FormGroup>
                        <Label for="selectedKeyType">Select a Feature:</Label>
                        <Dropdown options={this.state.keyTypes} value={this.state.selectedKeyType} onChange={this._onkeyTypeSelect} placeholder="Select a key type" id="selectedKeyType" />
                    </FormGroup>
                    <FormGroup>
                        <Label for="specificKey">Insert a specific key (optional)</Label>
                        <Input onChange={(event) => this.setState({ key: event.target.value })} type="text" id="specificKey" placeholder="insert a specific key" />
                    </FormGroup>
                    <FormGroup>
                        <Label for="datePickerRow" >Date range (optional)</Label>
                        <Row id="datePickerRow">
                            <DatePicker className="DatePicker"
                                placeholderText="From date"
                                selected={this.state.fromDate}
                                onChange={fromDate => this.setState({ fromDate })}
                                selectsStart
                                startDate={this.state.fromDate}
                                endDate={this.state.toDate}
                                isClearable
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                dateFormat="dd/MM/yyyy"
                                withPortal />

                            <DatePicker className="DatePicker"
                                placeholderText="To date"
                                selected={this.state.toDate}
                                onChange={toDate => this.setState({ toDate })}
                                selectsEnd
                                startDate={this.state.fromDate}
                                endDate={this.state.toDate}
                                minDate={this.state.fromDate}
                                isClearable
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                dateFormat="dd/MM/yyyy"
                                withPortal />
                        </Row>
                    </FormGroup>

                    <FormGroup>
                        <Label for="pageSizeId">Enter the amount of keys per page</Label>
                        <NumericInput id="pageSizeId" min={1} max={500} value={this.state.pageSize} onChange={this._onPageSizeChanged} />
                    </FormGroup>
                    <FormGroup>
                        <Button color="primary" onClick={this._onGetKeyStatusClicked}>{this._getSearchBtnText()}</Button>
                    </FormGroup>
                </Form>
                <Alert color="danger" fade={false} isOpen={this.state.showError}>
                    {this.state.errorMessage}
                </Alert>
                {keyTable}
            </Container>
        );
    }
}

export default withRouter(KeyStatus);
