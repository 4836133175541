import React, { useState, useEffect } from 'react';
import { Input, Button, Container, Alert } from 'reactstrap';
import Axios from 'axios';
import urls from '../../Services/Urls'
import './Login.css'
import { withRouter } from 'react-router-dom';

const Login = (props) => {

    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const token = localStorage.getItem("access_token");

        if (token) {
            const base64Url = token.split('.')[1]
            const base64 = base64Url.replace('-', '+').replace('_', '/')
            const { exp } = JSON.parse(window.atob(base64))
            if (Date.now() >= exp * 1000) {
                setErrorMessage("Login timeout expired");
                localStorage.removeItem("access_token");
                props.refreshNavMenu();
            }
        }
    });

    const sendLogin = (e) => {
        e.preventDefault();

        // reset error message
        setErrorMessage('');


        window.grecaptcha.enterprise.ready(async () => {
            const token = await window.grecaptcha.enterprise.execute('6Lep-3YqAAAAAGNBinDBHP5dfRQ1XgKC_DhPD11w', { action: 'login' });

            const AuthenticationModel = {
                Username: userName,
                Password: password,
                reCaptchaToken: token
            }

            Axios.post(urls.postLogin, AuthenticationModel).then(response => {
                localStorage.setItem("access_token", response.data.token);
                props.refreshNavMenu();
                props.history.push('/')
            }).catch(error => {
                if (error.response.status === 401) {
                    if (error.response.data)
                        setErrorMessage(error.response.data);
                    else
                        setErrorMessage('Wrong username or password');
                }
                console.log(error);
            });
        });
    }

    return (
        <Container>
            <Alert isOpen={errorMessage !== ''}>{errorMessage}</Alert>
            <h1>Login</h1>
            <p>Login to get more options.</p>
            <Input className='Input' type="input" name="userName" id="userName" placeholder="Username" onChange={e => setUserName(e.target.value)} />
            <Input className='Input' type="password" name="password" id="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
            <Button onClick={sendLogin}>Login</Button>
        </Container>
    )
}

export default withRouter(Login);