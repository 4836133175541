import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import styles from './Activate.module.css'
import { Upload } from '../Upload/Upload';
import urls from '../../Services/Urls.js';
import { Redirect, withRouter } from 'react-router-dom';

const Activate = (props) => {

    useEffect(() => {
        const token = localStorage.getItem("access_token");

        if (token) {
            const base64Url = token.split('.')[1]
            const base64 = base64Url.replace('-', '+').replace('_', '/')
            const { exp } = JSON.parse(window.atob(base64))
            if (Date.now() >= exp * 1000) {
                localStorage.removeItem("access_token");
                props.refreshNavMenu();
                props.history.push('/');
            }
        }
    }, []);

    if (!localStorage.getItem("access_token"))
        return <Redirect to='/login' />

    return (
        <Container className={styles.Activate}>
            <h1>Activate</h1>
            <p>Select Activate files from the PLC to activate them.</p>

            <ul>
                <li><strong>Activate</strong>. There you can send an 'Activate request' file, and get the 'Activate respons' file for the customer. This is needed if the customer PLC is not connected to the Ethernet, he should need to export a Activation request file and send it to support, and get back the 'Activate respons' file.</li>
            </ul>

            <Upload sendToUrl={urls.postKeyActivateFile} />
        </Container>
    );
}

export default withRouter(Activate);